import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    createRef
} from 'react'
import classnames from 'classnames'
import * as styles from '../styles/developments.module.css'

const ProjectTypes = ({ projects = [], active = null, setActive }) => {
    const [firstEle, setFirstEle] = useState(0)
    const [slideX, setSlideX] = useState(0)
    const [wrapWidth, setWrapWidth] = useState(0)
    const [totalChildrenWidth, setTotalChildrenWidth] = useState(0)
    const [childrenWidth, setChildrenWidth] = useState([])
    const [isButtonsVisible, setIsButtonsVisible] = useState(false)
    const [isNextEnabled, setIsNextEnabled] = useState(true)
    const wrapperRef = useRef()
    const childrenRefs = useRef(projects.map(() => createRef()))
    const handleEleChange = useCallback(
        firstEle => {
            const childrenBefore = childrenWidth.slice(0, firstEle)
            const maxSlide = totalChildrenWidth - wrapWidth
            const translateX = childrenBefore.reduce((total, num) => {
                return total + num
            }, 0)
            const isSlideEnabled = maxSlide > 0 && translateX < maxSlide
            const slideValue = isSlideEnabled ? translateX : maxSlide
            const finalSlide = slideValue < 0 ? 0 : -1 * slideValue
            setIsNextEnabled(isSlideEnabled)
            setSlideX(finalSlide)
        },
        [
            childrenWidth,
            totalChildrenWidth,
            wrapWidth,
            setIsNextEnabled,
            setSlideX
        ]
    )
    useEffect(() => {
        handleEleChange(firstEle)
    }, [firstEle, handleEleChange])
    useEffect(() => {
        const detectEleSizes = () => {
            const widths = childrenRefs.current.map(ref => {
                const width = ref.current.getBoundingClientRect().width
                return width
            })
            setChildrenWidth(widths)
            const totalWidth = widths.reduce((total, num) => {
                return total + num
            }, 0)
            setTotalChildrenWidth(totalWidth)
            const wrapWidth = wrapperRef.current.getBoundingClientRect().width
            setWrapWidth(wrapWidth - 72)
            const isVisible = totalWidth > wrapWidth
            setIsButtonsVisible(isVisible)
        }
        detectEleSizes()
        window.addEventListener('resize', detectEleSizes)
        return () => window.removeEventListener('resize', detectEleSizes)
    }, [])
    const goPrev = () => {
        if (firstEle > 0) {
            setFirstEle(ele => ele - 1)
        }
    }
    const goNext = () => {
        if (firstEle < projects.length - 1 && isNextEnabled) {
            setFirstEle(ele => ele + 1)
        }
    }
    return (
        <div
            ref={wrapperRef}
            className={styles.typesOuterWrapper}
            style={{
                padding: isButtonsVisible ? '0 36px' : '0 1rem'
            }}
        >
            <button
                disabled={firstEle === 0}
                className={classnames('z-50', styles.btnArrow, styles.btnPrev, {
                    hidden: !isButtonsVisible,
                    'opacity-60': firstEle === 0
                })}
                onClick={goPrev}
            >
                <span>Previous</span>
            </button>
            <div
                className={styles.typesWrapper}
                style={{
                    transition: 'all .3s ease-in-out',
                    transform: `translateX(${slideX}px)`
                }}
            >
                {projects.map((type, t) => {
                    const btnClass = classnames(styles.btnItem, {
                        [styles.activeType]: type.slug === active?.slug
                    })
                    return (
                        <div
                            key={`${t}-${type.slug}`}
                            ref={childrenRefs.current[t]}
                        >
                            <button
                                onClick={() => setActive(type)}
                                className={btnClass}
                            >
                                {type.name}
                            </button>
                        </div>
                    )
                })}
            </div>
            <button
                disabled={firstEle === projects.length - 1 || !isNextEnabled}
                className={classnames('z-50', styles.btnArrow, styles.btnNext, {
                    hidden: !isButtonsVisible,
                    'opacity-60':
                        firstEle === projects.length - 1 || !isNextEnabled
                })}
                onClick={goNext}
            >
                <span>Next</span>
            </button>
        </div>
    )
}

export default ProjectTypes
