import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import classnames from "classnames"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import DevSingle from "../../components/devsingle"
import GridItem from "../../components/gridItem"
import ProjectTypes from "../../components/projectTypes"
import { Button } from "../../components/buttons"
import {
    MoreInfoForm,
    handleSubmit,
} from "./development-project-moreinfo-template"
import * as styles from "../../styles/developments.module.css"
import { extractSrcFromIframe } from "../../utils/utils"

const TypeBlock = ({ activeType, setActiveType, projectTypes }) => {
    const typeRef = useRef()
    const [isTypeBarVisible, setIsTypeBarVisible] = useState(false)
    const typeClass = classnames({
        [styles.typeBarOpened]: isTypeBarVisible,
    })
    const toggleTypeBar = () => setIsTypeBarVisible(!isTypeBarVisible)
    useEffect(() => {
        setIsTypeBarVisible(false)
    }, [activeType])
    useEffect(() => {
        const checkIfClickOutside = e => {
            if (
                isTypeBarVisible &&
                typeRef.current &&
                !typeRef.current.contains(e.target)
            ) {
                setIsTypeBarVisible(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickOutside)
        }
    }, [isTypeBarVisible])
    return (
        <div
            className={`relative mb-12 lg:mb-0 ${styles.typeBarWrap}`}
            ref={typeRef}
        >
            <button
                className={`relative py-4 mx-auto text-white block lg:hidden ${styles.typeToggle}`}
                onClick={toggleTypeBar}
            >{`${activeType?.name}`}</button>
            <ul
                className={`absolute lg:static justify-start items-center mb-16 px-4 ${styles.typeBar} ${typeClass}`}
            >
                {projectTypes.map((type, t) => {
                    const typeClass = classnames({
                        [styles.activeType]: type?.slug === activeType?.slug,
                    })
                    return (
                        <li key={`${t}-${type?.slug}`}>
                            <button
                                className={typeClass}
                                onClick={() => setActiveType(type)}
                            >
                                {type?.name}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div
                className={`mb-16 type-bar-slider ${styles.typeBarSlide} ${typeClass}`}
            >
                <ProjectTypes
                    projects={projectTypes}
                    active={activeType}
                    setActive={setActiveType}
                />
            </div>
        </div>
    )
}

const GalleryBlock = ({ gallery }) => {
    const thumbRef = useRef(null)
    const slideRef = useRef(null)
    const [thumbSlider, setThumbSlider] = useState(null)
    const [slideSlider, setSlideSlider] = useState(null)

    const goToPrev = () => {
        if (slideRef) slideRef.current.slickPrev()
    }
    const goToNext = () => {
        if (slideRef) slideRef.current.slickNext()
    }
    const goToThumbPrev = () => {
        if (thumbRef) thumbRef.current.slickPrev()
    }
    const goToThumbNext = () => {
        if (thumbRef) thumbRef.current.slickNext()
    }
    useEffect(() => {
        setSlideSlider(slideRef.current)
        setThumbSlider(thumbRef.current)
    }, [])
    const thumbSettings = {
        arrows: false,
        dots: false,
        slidesToShow: 8,
        rows: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        focusOnSelect: true,
        variableWith: true,
        infinite: false,
        asNavFor: slideSlider,
    }
    const bigSettings = {
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        asNavFor: thumbSlider,
    }
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">Gallery</h2>
            <div className="mb-8 developments-single-slider">
                <div className="slides-thumbnail hidden lg:block relative">
                    <Slider ref={thumbRef} {...thumbSettings}>
                        {gallery.map((t, i) => {
                            return (
                                <div key={`thumb-${i}`}>
                                    <img
                                        src={t?.mediaItemUrl}
                                        alt={t?.altText}
                                    />
                                </div>
                            )
                        })}
                    </Slider>
                    <button
                        className={`hidden lg:block ${styles.slideNav} ${styles.slidePrev}`}
                        onClick={goToThumbPrev}
                    >
                        Prev
                    </button>
                    <button
                        className={`hidden lg:block ${styles.slideNav} ${styles.slideNext}`}
                        onClick={goToThumbNext}
                    >
                        Next
                    </button>
                </div>
                <div className="slides-main relative">
                    <Slider ref={slideRef} {...bigSettings}>
                        {gallery.map((b, i) => {
                            return (
                                <div key={`main-${i}`}>
                                    <div className="text-center">
                                        <img
                                            className="mx-auto"
                                            src={b?.mediaItemUrl}
                                            alt={b?.altText}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    <button
                        className={`block lg:hidden ${styles.slideNav} ${styles.slidePrev}`}
                        onClick={goToPrev}
                    >
                        Prev
                    </button>
                    <button
                        className={`block lg:hidden ${styles.slideNav} ${styles.slideNext}`}
                        onClick={goToNext}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

const VideoBlock = ({ blocks }) => {
    // if youtube and dailymotion both exist, choose dailymotion
    const embedBlock = blocks?.filter(v => v?.name == "core/embed")
    let videoBlock = []
    if (embedBlock?.length > 1) {
        videoBlock.push(
            embedBlock.find(
                v => v?.attributes?.providerNameSlug == "dailymotion"
            )
        )
    } else {
        videoBlock = embedBlock
    }

    if (videoBlock?.length == 0) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Video
            </h2>
            <div className="mb-8">
                <div className={styles.videoFrame}>
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src={videoBlock.find((_v, i) => i == 0).attributes?.url}
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        title="Kota Wisata"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </div>
        </>
    )
}

const VirtualLinkBlock = ({ virtualLink }) => {
    if (!virtualLink) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Virtual Link
            </h2>
            <div className="mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {virtualLink?.link?.map((item, key) => {
                        return (
                            <GridItem
                                key={key}
                                isExternalLink
                                bgImage={item?.image?.mediaItemUrl}
                                label={item?.label}
                                link={item?.link?.url}
                            />
                        )
                    })}
                </div>
            </div>
        </>
    )
}

const FacilityBlock = ({ facilities }) => {
    if (!facilities) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Facilities
            </h2>
            <div className="mb-8 mx-4 lg:mx-0">
                <div
                    className={`grid grid-cols-2 lg:grid-cols-5 gap-0 ${styles.facilitiesWrap}`}
                >
                    {facilities?.map((facility, i) => {
                        return (
                            <div key={i}>
                                <div>
                                    <img
                                        height={50}
                                        src={facility?.icon?.mediaItemUrl}
                                        alt={facility?.icon?.altText}
                                    />
                                </div>
                                {facility?.title}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

const FloorPlanBlock = ({ floorPlan }) => {
    if (!floorPlan) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Floor Plan
            </h2>
            {floorPlan?.map((f, i) => {
                return (
                    <div key={i} className="mb-8 mx-4 lg:mx-0">
                        <img src={f?.mediaItemUrl} alt={f?.altText} />
                    </div>
                )
            })}
        </>
    )
}

const SpecificationBlock = ({ specification, data, activeType }) => {
    if (!specification) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Specification
            </h2>
            <div className="mx-4 lg:mx-0 mb-8">
                <p>
                    {`${data?.title} - ${activeType?.name}`}
                    <br />
                    {specification?.map((s, i) => {
                        return (
                            <React.Fragment key={i}>
                                {`${s?.label} : ${s?.value}`}
                                <br />
                            </React.Fragment>
                        )
                    })}
                </p>
            </div>
        </>
    )
}

const DownloadBlock = ({ downloadLink }) => {
    if (!downloadLink) return <></>
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Download
            </h2>
            <div className="mx-4 lg:mx-0 mb-8">
                <p>
                    Visit the e-Catalog to check out other special offers from
                    us or download the e-Brochure.
                </p>
            </div>
            <div>
                <Button>
                    <a
                        className={styles.dlBtn}
                        target="_blank"
                        rel="noreferrer"
                        href={downloadLink}
                    >
                        Download
                    </a>
                </Button>
                <Button>
                    <a
                        className={styles.ectlgBtn}
                        target="_blank"
                        rel="noreferrer"
                        href="https://ecatalog.sinarmasland.com"
                    >
                        E-Catalog
                    </a>
                </Button>
            </div>
        </>
    )
}

const FollowBlock = ({ link, userName }) => {
    if (!link || !userName) return <></>
    const textClass = classnames("ml-4 block", styles.igText)
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Follow
            </h2>
            <div className="mx-4 lg:mx-0 mb-8">
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-start items-center"
                >
                    <span className={styles.igIcon} />
                    <span className={textClass}>{userName}</span>
                </a>
            </div>
        </>
    )
}

const MoreInfoBlock = ({ data }) => {
    return (
        <>
            <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8 mt-16">
                Get More Information
            </h2>
            <div className="mx-4 lg:mx-0 mb-8">
                <p>
                    Kota Wisata will never sell or share your information with a
                    third party
                </p>
                <MoreInfoForm handleSubmit={e => handleSubmit(e, data)} />
            </div>
        </>
    )
}

const DevelopmentsTypeSinglePage = ({ pageContext }) => {
    const data = pageContext?.data
    let prev = undefined
    let next = undefined

    if (data?.nearby.length > 1) {
        data?.nearby.forEach((item, key) => {
            if (key === 0) {
                prev = {
                    link: `${pageContext?.basicPath}${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            } else {
                next = {
                    link: `${pageContext?.basicPath}${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            }
        })
    }
    const projectTypes = data?.projectTypes?.nodes
    const [activeType, setActiveType] = useState(
        projectTypes.length > 0 ? projectTypes[0] : ""
    )

    const goToForm = () => {
        const form = document.getElementById("form")
        const { top } = form.getBoundingClientRect()
        window.scrollTo({ top, behaviour: "smooth" })
    }

    return (
        <Layout isTestimonialsHidden activeMenuId={3}>
            <Seo
                title={data?.title}
                description={data?.seo?.metaDesc}
                date={data?.date}
                img={data?.featuredImage?.node?.mediaItemUrl}
                imgHeight={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.height
                }
                imgWidth={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.width
                }
            />
            {data?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <PageHeader
                                key={i}
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    {
                                        label: `Developments / ${pageContext?.propTypes?.name}`,
                                        link: pageContext?.basicPath,
                                    },
                                    { label: coverMain?.title, link: null },
                                ]}
                            />
                        )
                    case "acf/project-w-type":
                        const projectWithType = item?.projectWithType
                        if (projectWithType?.type === activeType?.slug) {
                            const gallery = projectWithType?.gallery
                            const facilities = projectWithType?.facilites
                            const floorPlan = projectWithType?.floorPlan
                            const specification = projectWithType?.specification
                            const embedMap = data?.blocks.find(
                                item => item.name == "core/html"
                            )?.originalContent
                            const instagram = data?.blocks.find(
                                item => item.name == "acf/instagram"
                            )?.instagram
                            const virtualLink = data?.blocks.find(
                                item => item.name == "acf/virtual-link"
                            )?.virtualLink

                            return (
                                <DevSingle
                                    key={i}
                                    logo={
                                        data?.projectCustomfields?.logo
                                            ?.mediaItemUrl
                                    }
                                    title={data?.title}
                                    mapSrc={
                                        embedMap
                                            ? extractSrcFromIframe(embedMap)
                                            : null
                                    }
                                    activePage="Types"
                                    pageLinks={pageContext?.pageSideLink}
                                    prev={prev}
                                    next={next}
                                    goToForm={
                                        <Button onClick={goToForm}>
                                            Get More Info
                                        </Button>
                                    }
                                >
                                    <TypeBlock
                                        activeType={activeType}
                                        setActiveType={setActiveType}
                                        projectTypes={projectTypes}
                                    />
                                    <GalleryBlock gallery={gallery} />
                                    <VideoBlock blocks={data?.blocks} />
                                    <VirtualLinkBlock
                                        virtualLink={virtualLink}
                                    />
                                    <FacilityBlock facilities={facilities} />
                                    <FloorPlanBlock floorPlan={floorPlan} />
                                    <SpecificationBlock
                                        specification={specification}
                                        data={data}
                                        activeType={activeType}
                                    />
                                    <DownloadBlock
                                        downloadLink={
                                            data?.projectCustomfields
                                                ?.downloadLink?.url
                                        }
                                    />
                                    <FollowBlock
                                        link={instagram?.instagram}
                                        userName={instagram?.title}
                                    />
                                    <div id="form" className="lg:hidden">
                                        <MoreInfoBlock data={data} />
                                    </div>
                                </DevSingle>
                            )
                        }
                }
            })}
        </Layout>
    )
}

export default DevelopmentsTypeSinglePage
